import React from 'react';
import deliveryos from '../deliveryos.png';


function Footer() {
  return (
    <footer className="footer">
        <div className="container">
            <div className="footer-content">
                <img src={deliveryos} alt="DeliveryOS" />
                <p>
                    Automate and reduce multi-step manual cargo release processes to minutes for Carriers and Terminals using AI. Collect logistics payments, confirmation instantly, and handle AR reconciliation seamlesslessly
                </p>
            </div>

            <div className="footer-links">
                <p>support@deliveryos.com</p>
            </div>
        </div>

        <div class="copyright container">
            ©2024 DeliveryOS Technologies Inc.
        </div>
    </footer>

    
  );
}

export default Footer;