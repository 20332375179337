import React from 'react';

function Features() {
  return (
    <section className="features">
      <div className="container">
        <span className="subtitle">What we do</span>
        <h2>One platform to collect payments, <br/> offer your customers financing, and release cargo.</h2>
        
        <div className="feature-grid">
          <div className="feature">
            <ion-icon name="wallet"></ion-icon>
            <h3><i className="payment-icon"></i> Payment</h3>
            <p>Collect payments from your customers 3x faster without human effort. Confirmation and receipt is instant. Use AI to reconcile payments from other channels.</p>
          </div>
          <div className="feature">
            <ion-icon name="card"></ion-icon>
            <h3>Financing</h3>
            <p>Give your customers the ability to pay later, and get paid immediately. There is no risk to you, and you can offer this to your customers as a value-add.</p>
          </div>
          <div className="feature">
            <ion-icon name="link-outline"></ion-icon>
            <h3>Cargo Release</h3>
            <p>With our AI-powered cargo release system, documents are processed 10x faster. Reduce errors and save time with our advanced release features.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;