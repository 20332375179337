import React from 'react';
import logo from '../logo.png'; // Import the image

function Header() {
    return (
   
        <div className="container">
            <header className="header">
                <div>
                    <img src={logo} alt="Logo" className="logo" />
                </div>
                <div>
                    <p>support@deliveryos.com</p>
                </div>
            </header>
        </div>
   
    );
}

export default Header;