import React from 'react';
import quickbooksLogo from '../quickbooks.svg';
import sapLogo from '../sap.svg';
import salesforceLogo from '../salesforce.svg';
import port from '../port.jpg';



function Hero() {
  return (
    <section className="hero">
      <div className="container">
        <div>
            <h1>A better way to collect logistics payments.</h1>
            <p className='hero_subtitle'>Carriers and Terminals can now collect payments faster, and release cargo instantly using AI.</p>

            <div className="integrations">
                <p>Integrates with:</p>
                <img src={sapLogo} alt="SAP Logo" className='sap' />
                <img src={salesforceLogo} alt="Salesforce Logo" />
                <img src={quickbooksLogo} alt="QuickBooks Logo" />
            </div>
        </div>

        <div>
            <div className="hero-image-container">
                <img src={port} alt="Hero" className="hero-image" />
            </div>

            <div className="payment-card"> 
                <div>
                    <ion-icon name="card"></ion-icon>
                    <h2>₦ 1000</h2>

                    <p>APM Terminal</p>
                    <p><strong>Invoice #:</strong> AD2BD9CF-0013</p>
                    <p><strong>Memo:</strong> Aug 1, 2024 - Aug 31, 2024</p>
                </div>

                <div>   
                    <p className='transfer'><span></span> Pay with Transfer</p>
                    <p className='later'>
                        Pay Later
                        <i>When would you like to pay?</i>
                        <p>
                            <span className='_active'>– 30 Days</span>
                            <span>45 Days</span>
                        </p> 
                    </p>

                    <p className='continue'>Continue</p>
                </div>
            </div>
        </div>
      </div>


    </section>
  );
}

export default Hero;